<template>
  <div class="auth-container">
    <div
      light-theme="white"
      dark-theme="dark-to-lighter"
      class="auth-container__form-side bg--color-01"
    >
      <form
        ref="authContainerForm"
        v-bind="$attrs"
        class="auth-container__form-side-container"
        @submit.prevent="$emit('submit')"
      >
        <h1 v-if="!hideHeader" class="auth-container__header txt txt--36px txt--height-36px txt--bold txt--color-04">
          {{ formName }}
        </h1>
        <div class="auth-container__form">
          <slot
            v-bind="props"
            name="default"
          />
        </div>
        <div class="auth-container__btn">
          <slot
            v-bind="props"
            name="btn"
          />
        </div>
        <div class="auth-container__bottom">
          <slot
            v-bind="props"
            name="bottom"
          />
        </div>
      </form>
    </div>
    <div
      light-theme="light-gray"
      dark-theme="dark-to-lighter"
      mask="on-dark"
      class="auth-container__img-side bg--theme-02"
    >
      <Slider />
    </div>
  </div>
</template>

<script>
import Slider from '~/components/ui-kit/slider/Slider'

export default {
  name: 'AuthContainer',
  components: { Slider },
  props: {
    formName: {
      type: String,
      required: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      focused: false,
    }
  },
  computed: {
    props () {
      return { focused: this.focused }
    },
  },
  mounted () {
    setTimeout(() => {
      const form = this.$refs.authContainerForm[0]
      form.addEventListener('focus', () => {
        this.focused = true
      }, true)
    }, 111)
  },
}
</script>

<style lang="scss" scoped>
.auth-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  @include bp(0, m) {
    grid-template-columns: 1fr;

    .slider-wrapper__outer {
      display: none;
    }
  }

  &__form-side {
    flex: 1;
    // border: 1px solid red;
    display: flex;
  }

  &__form-side-container {
    // margin-left: 20%;
    // margin-right: 20%;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: toRem(20px) auto;
    padding: 0 toRem(20px);

    @include bp(s) {
      margin: toRem(20px) auto;
    }
    // max-width: toRem(250px);
    max-width: toRem(545px);
  }

  &__header {
    margin-bottom: toRem(25px);
  }

  &__btn,
  &__bottom {
    margin-top: toRem(10px);
  }

  &__img-side {
    display: none;

    @include bp(m) {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: hidden;
    }

    img {
      max-width: 100%;
      max-height: 80%;

      @include bp(xl) {
        max-width: toRem(569px);
      }
      // width: 100%;
    }
  }
}
</style>
