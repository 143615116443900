<template>
  <div class="slider-wrapper__outer">
    <div
      ref="slider"
      class="slider-wrapper"
    >
      <div v-if="!isSliderLoaded">
        <img :src="require('@/assets/img/slider/slide-1.png')" loading="lazy">
      </div>
      <div v-else :id="'slider'+sliders.length" class="slide-wrapper">
        <div
          v-for="slide in sliders"
          :id="slide.id"
          :key="slide.id"
          class="slide"
          :style="
            `width: ${ slide.id === sliders.length ? sliderWidth : sliderWidth }px;
            transform: translateX(-${sliderPosition}px);`"
        >
          <div
            :style="`width: ${sliderWidth}px;`"
            class="slide-content"
          >
            <div
              class="img-wrapper"
              :class="slide.class ? slide.class : ''"
            >
              <img :src="slide.img" class="slider-image" alt="slider image">
            </div>
            <div class="slider__block-pagination">
              <img
                class="mr-38 slider__block-pagination-icon"
                :src="require('assets/img/slider/pagination-left.svg')"
                alt=""
                @click="prevSlide(), stopAutoplay()"
              >
              <div class="slider__block-with-text">
                <div class="txt txt--color-04 txt--weight-600 txt--22px txt-height--30px">
                  {{ slide.title }}
                </div>
                <div
                  class="slider__block-with-text-description txt txt--color-03 txt--weight-400 txt--16px txt-height--18px mt-8"
                >
                  {{ slide.description }}
                </div>
              </div>
              <img
                class="ml-38 slider__block-pagination-icon"
                :src="require('assets/img/slider/pagination-right.svg')"
                alt=""
                @click="nextSlide(), stopAutoplay()"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Slider',
  data () {
    return {
      sliders: [{
        id: 1,
        title: this.$t('registration.firstSlideTitle'),
        description: this.$t('registration.firstSlideDescription'),
        img: require('assets/img/slider/rdx-slide-1.png'),
      }, {
        id: 2,
        title: this.$t('registration.secondSlideTitle'),
        description: this.$t('registration.secondSlideDescription'),
        img: require('assets/img/slider/rdx-slide-2.png'),
      }, {
        id: 3,
        title: this.$t('registration.thirdSlideTitle'),
        description: this.$t('registration.thirdSlideDescription'),
        img: require('assets/img/slider/rdx-slide-3.png'),
      }, {
        id: 4,
        title: this.$t('registration.fourthSlideTitle'),
        description: this.$t('registration.fourthSlideDescription'),
        img: require('assets/img/slider/rdx-slide-4.png'),
      }, {
        id: 5,
        title: this.$t('registration.fifthSlideTitle'),
        description: this.$t('registration.fifthSlideDescription'),
        img: require('assets/img/slider/rdx-slide-5.png'),
      }],
      sliderPosition: 0,
      selectedSlide: 1,
      sliderWidth: 345,
      autoplayIntervalId: null,
      isSliderLoaded: false,
    }
  },
  created () {
    if (process.client) {
      window.addEventListener('resize', this.updateSliderWidth)
    }
  },
  destroyed () {
    if (process.client) {
      window.removeEventListener('resize', this.updateSliderWidth)
    }
  },
  mounted () {
    this.getSliderWidth()
    this.sliderAutoPlay()
  },
  methods: {
    updateSliderWidth () {
      this.sliderPosition = 0
      this.sliderWidth = this.$refs.slider.clientWidth
    },
    getSliderWidth () {
      this.isSliderLoaded = true
      this.updateSliderWidth()
    },
    nextSlide () {
      if (this.selectedSlide <= this.sliders.length) {
        this.selectedSlide++
        this.sliderPosition += (this.sliderWidth) + 18
      }

      if (this.selectedSlide === this.sliders.length + 1) {
        this.selectedSlide = 1
        this.sliderPosition = 0
      }
    },
    prevSlide () {
      this.selectedSlide -= 1
      this.sliderPosition -= (this.sliderWidth) + 18

      if (this.selectedSlide < 1) {
        this.selectedSlide = 5
        this.sliderPosition -= this.sliderPosition * this.selectedSlide
      }
    },
    sliderAutoPlay () {
      this.autoplayIntervalId = setInterval(() => {
        this.nextSlide()
      }, 3000)
    },
    stopAutoplay () {
      clearInterval(this.autoplayIntervalId)
    },
  },
}
</script>

<style lang="scss" scoped>

.slider-wrapper__outer {
  width: 100%;
  height: 100%;

  .slider-wrapper {
    max-width: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .slide-wrapper {
      width: fit-content;
      height: 100%;
      display: flex;
      gap: 18px;

      .slide {
        //transition-duration: 6s;

        .slide-content {
          height: 100%;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr max-content;

          .slider__block-pagination {
            position: absolute;
            bottom: toRem(60px);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            &-icon {
              cursor: pointer;
            }
          }

          .slider__block-with-text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 260px;

            &-description {
              text-align: center;
              max-width: 250px;
            }
          }

          .img-wrapper {
            position: relative;
            max-width: 100%;
            width: 100%;
            height: auto;
            box-sizing: border-box;
            overflow: hidden;
            background-color: rgb(249, 249, 249);

            .slider-image {
              max-width: 100%;
              width: 100%;
              height: 80%;
              object-fit: contain;

              @include bp(ml) {
                object-fit: cover;
              }
            }
          }

          .slider-content {
            max-width: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-rows: max-content;
            grid-row-gap: 2px;
          }
        }
      }
    }
  }
}

</style>
