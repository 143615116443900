<template>
  <div class="auth-container bg--color-02">
    <div class="auth-container__form-side">
      <form
        ref="authContainerForm"
        v-bind="$attrs"
        class="
          auth-container__form-side-container
          bg--color-01
          bor--radius-12
          p-24
          sha--03
        "
        @submit.prevent="$emit('submit')"
      >
        <h1
          class="
            auth-container__header
            txt--26px txt--height-34px txt--bold txt--color-04 mb-28
          "
        >
          {{ formName }}
        </h1>
        <div class="auth-container__form">
          <slot v-bind="props" name="default" />
        </div>
        <div class="auth-container__btn">
          <slot v-bind="props" name="btn" />
        </div>
        <div v-if="$slots.bottom" class="auth-container__bottom">
          <slot v-bind="props" name="bottom" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthContainer',
  props: {
    formName: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      focused: false,
    }
  },
  computed: {
    props () {
      return { focused: this.focused }
    },
  },
  mounted () {
    setTimeout(() => {
      const form = this.$refs.authContainerForm[0]
      form.addEventListener(
        'focus',
        () => {
          this.focused = true
        },
        true,
      )
    }, 111)
  },
}
</script>

<style lang="scss" scoped>
.auth-container {
  overflow-x: hidden;
  display: flex;
  min-height: calc(100vh - #{$h-Header});
  flex: 1;
  align-items: center;

  &__form-side {
    flex: 1;
    display: flex;
    z-index: 2;
  }

  &__form-side-container {
    height: fit-content;

    position: relative;
    display: flex;
    flex: 2;
    flex-direction: column;
    max-width: toRem(367px);
    margin: toRem(20px) auto;
  }

  &__header {
    // margin-bottom: toRem(25px);
  }

  &__btn,
  &__bottom {
    margin-top: toRem(24px);
  }

  &__img-side {
    display: none;

    @include bp(m) {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      max-width: 100%;
      max-height: 80%;

      @include bp(xl) {
        max-width: toRem(569px);
      }
      // width: 100%;
    }
  }
}
</style>
